<template>
  <div class="container">
    <div class="content">
      <el-table :data="oplogList" stripe style="width: 100%" height="100%">
        <el-table-column prop="operUsername" label="用户" show-overflow-tooltip width="100">
        </el-table-column>
        <el-table-column prop="operUri" label="访问接口" show-overflow-tooltip width="250">
        </el-table-column>
        <el-table-column prop="operReqParam" label="传递参数" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="operIp" label="访问IP" show-overflow-tooltip width="150">
        </el-table-column>
        <el-table-column prop="operCreateTime" label="访问时间" show-overflow-tooltip width="150">
          <template slot-scope="{row}">
            {{row.operCreateTime|timeFormat}}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      currentPage: 1,
      total: 0,
      oplogList: []
    }
  },
  created () {
    this.initTable()
  },
  methods: {
    async handleCurrentChange (val) {
      this.currentPage = val
      this.initTable()
    },
    // 初始化表格
    async initTable () {
      const data = {}
      data.pageNo = this.currentPage
      data.pageSize = 10
      const { data: result } = await this.$axios.get('/operatelogging/list', { params: data })
      if (result.code === 200) {
        this.oplogList = result.data.result
        this.total = result.data.total
      } else {
        this.$message.error(result.msg)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
